import React from 'react'

import iphoneImage from '../images/iphone-mirror.png'

const ShortSection3 = () => (
	<div className="mw7 w-100 w-75-ns center w pt2 pt5-l pb5 pb6-ns">
		<div className="flex flex-wrap justify-center">
			<div className="pa2 w-100 w-75-ns pt6">
				<h2 className="white f2 fw7 lh-headline shawdow">A Friendly<br/>Environment</h2>
				<p className="white f4 f5-ns fw3 w-100 w-80-ns lh-copy pl4 pr4 pl2-m pr2-m center shawdow">
					Frendli is NOT for dating. There are countless other apps out there designed for dating, Frendli is a fun and safe place for people to make new friends.
				</p>
			</div>
			<div className="pa2 w-100 w-25-ns pt2 pt6-ns">
				<img alt="Frendli on Iphone" className="iphone-mirror" src={iphoneImage}/>
			</div>
		</div>
	 </div>
)

export default ShortSection3
