import React from 'react'
import Link from 'gatsby-link'

import check from '../images/icons/check.png'
import chat from '../images/icons/chat.png'
import explore from '../images/icons/explore.png'

const ShortSection2 = () => (
	<div className="center mw7 w-100 pt2 pt5-ns pb5">
		<div className="flex flex-wrap justify-center pb1">
			<div className="pa2 w-100 w-third-ns">
				<img alt="Checkbox" className="w-25 w-50-ns fl fn-ns" src={check} />
				<h3 className="f3 lh-headline flex flex-wrap mb0">
					<span className="w-50 w-100-ns tr tc-ns no-wrap">&nbsp;Find&nbsp;New&nbsp;</span>
					<span className="w-50 w-100-ns tl tc-ns">Friends</span>
				</h3>
				<p className="f6 pl3 fw3 pr3 mw7 mw5-ns center lh-copy tc">We deliver suggested new friends based on common interests, tastes, lifestyle and goals.</p>
			</div>
        	<div className="pa2 w-100 w-third-ns">
				<img alt="Chat Boxes" className="w-25 w-50-ns  fr fn-ns" src={chat}/>
				<h3 className="f3 lh-headline flex flex-wrap mb0">
					<span className="w-50 w-100-ns tr tc-ns">&nbsp;Connect&nbsp;</span>
					<span className="w-50 w-100-ns tl tc-ns">to&nbsp;Chat</span>
				</h3>
				<p className="f6 fw3 pl3 pr3 mw7 mw5-ns center lh-copy tc">Chat with your new groups of 1, 2 or 3 friends and get to know each other. We'll even help you break the ice.</p>
			</div>
        	<div className="pa2 w-100 w-third-ns">
				<img alt="Pinpoint Map" className="w-25 w-50-ns fl fn-ns" src={explore}/>
				<h3 className="f3 lh-headline flex flex-wrap mb0">
					<span className="w-50 w-100-ns tr tc-ns">&nbsp;Go&nbsp;Out&nbsp;</span>
					<span className="w-50 w-100-ns tl tc-ns">& Explore</span>
				</h3>
				<p className="f6 pl3 fw3 pr3 mw7 mw5-ns center lh-copy tc">Get discounts and special offers on activities we find of mutual interest for you and your friends.</p>
			</div>
		</div>
	 </div>
)

export default ShortSection2
