import React from 'react'
import Link from 'gatsby-link'
import GooglePlay from '../components/GooglePlay'
import AppleStore from '../components/AppleStore'

const IntroSection = () => (
	<div className="mw7 w-100 w-75-ns center w pt2 pt5-l pb5 pb6-ns">
		<div className="flex flex-wrap justify-center items-center v-mid mt4 mt0-ns">
			<div className="w-50-ns tc center pr3-ns pb4 pb1-ns">
				<h1 className="f2 lh-headline">Meet Frendli</h1>
				<p className="lh-copy ma0 pl4 pr4 pl2-m pr2-m fw3 shawdow-white">
					A free app that connects people with lots in common and finds them fun activities in their city to share.
				</p>
			</div>
			<div className="w-75 w-50-ns flex flex-wrap justify-center items-center pa0">
				<div className="video-responsive mt5-ns">
					<iframe
						title="About Frendli Video"
						src="https://www.youtube-nocookie.com/embed/dP7pyqbzeGw?rel=0"
						frameBorder="0"
						allow="encrypted-media"
						allowFullScreen="allowfullscreen"
						width="560" height="349"
					/>
					
				</div>
			</div>
		</div>

		<div className="mt4 mt-5ns w-100-ns tc center mb4 mb5-ns">
			<h2 className="f4 f4-l lh-headline">Sign Up For Frendli Today!</h2>
  			<div className="flex justify-center">
  				<div className="center dn db-ns w-25 pa2"/>
  					<GooglePlay/>
  					<AppleStore/>
				<div className="center dn db-ns w-25 pa2"/>
		  	</div>
	  	</div>
	 </div>
)

export default IntroSection
