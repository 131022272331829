import React from 'react'
import Link from 'gatsby-link'
import GooglePlay from '../components/GooglePlay'
import AppleStore from '../components/AppleStore'

const ShortSection4 = () => (
	<div  className="w-100 pt5 pb5 mt4">
		<div className="mw7 w-100 w-75-ns tc center">
	  		<h2 className="f3 f2-l lh-headline">Sign Up For Frendli Today!</h2>
  			<div className="flex justify-center">
  				<div className="center dn db-ns w-25 pa2"/>
  					<GooglePlay/>
  					<AppleStore/>
	  			<div className="center dn db-ns w-25 pa2"/>
		  	</div>
	  	</div>
	 </div>
)

export default ShortSection4
